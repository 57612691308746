import React from "react";
import PropTypes from "prop-types";
import PortfolioItem from "./PortfolioItem";

// ====

const Portfolio = (props) => {
  const { things } = props;

  // ====

  return (
    <>
      {things && (
        <>
          {things.map((node, index) => {
            return (
              <>
                <PortfolioItem node={node} />
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default Portfolio;

// ====

Portfolio.propTypes = {
  things: PropTypes.arrayOf,
};

Portfolio.defaultProps = {
  things: null,
};
