import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../utils/SEO";
import Portfolio from "../components/Portfolio/Portfolio";
import SmartText from "../utils/TextHandler";
import ImageHandler from "../utils/ImageHandler";
import Link from "../components/Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import { gsap } from "gsap";

// ====

const Index = (props) => {
  const { data } = props;
  let home;
  let portfolio;
  let contact;
  let settings;
  if (data) {
    home = JSON.parse(data.index.content);
    portfolio = JSON.parse(data.portfolio.content);
    contact = JSON.parse(data.contact.content);
    settings = JSON.parse(data.settings.content);
  }
  const openGraph = {
    type: "website",
    title: home.title,
    description: home.body,
  };

  const [modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".brand-navigation-bar",
        toggleClass: "brand-navigation-bar-sticky",
        start: "top 0",
        end: 999999,
        once: false,
      },
    });

    gsap.timeline({
      scrollTrigger: {
        trigger: ".brand-navigation-bar",
        toggleClass: "brand-navigation-bar-extended",
        start: "top -100",
        end: 999999,
        once: false,
      },
    });

    gsap.utils.toArray(".brand-animated").forEach((element) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleClass: "brand-animated-active",
          start: "top 75%",
          once: true,
        },
      });
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: ".brand-animated-zoom",
        toggleClass: "brand-animated-active",
        start: "top 80%",
        end: "top 20%",
        once: false,
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: ".brand-animated-burger",
        toggleClass: "mt-0",
        start: "top -200",
        end: 999999,
        once: false,
      },
    });
  }, []);

  return (
    <>
      <SEO openGraph={openGraph} meta={home.meta} />
      <div
        class="brand-background-video"
        style={{
          backgroundImage: `url(${ImageHandler(
            home.image.filename,
            "1920x1080"
          )})`,
          backgroundColor: `${home.background.color}`,
        }}
      >
        {modalStatus === false && (
          <iframe
            id={settings.video}
            class="brand-video-embed"
            src={`https://player.vimeo.com/video/${settings.video}?background=1`}
            width="100%"
            height="100%"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen="false"
            data-ready="true"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        )}
      </div>
      <article className="w-100">
        <section
          className="brand-screen p-3 p-md-5 d-flex justify-content-center align-items-center"
          id="home"
        >
          <div className="row p-3 p-md-5 border border-3 border-light w-100 h-100 text-light fs-3">
            <div className="col-12 col-md-6">
              {home.title && (
                <h1 className="brand-font display-1">{home.title}</h1>
              )}
              {home.body && <SmartText content={home.body} className="mb-5" />}
              <div class="d-grid gap-2 d-md-block">
                <Link to="#contact" icon="envelope" button="large" smooth>
                  Contact
                </Link>
                <Link
                  onClick={() => setModalStatus(true)}
                  icon="play"
                  button="large"
                  invert
                  className="ms-0 ms-md-3"
                >
                  Showreel
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section
          className="brand-screen-fluid p-3 p-md-5 d-flex flex-column justify-content-center align-items-start brand-background-black"
          style={{
            backgroundColor: `${portfolio.background.color}`,
          }}
          id="portfolio"
        >
          <div class="container">
            <div className="row text-light fs-3 justify-content-center">
              <div className="col-12 col-md-6 text-center">
                {portfolio.title && (
                  <h1 className="brand-font display-1">{portfolio.title}</h1>
                )}
                {portfolio.body && (
                  <SmartText content={portfolio.body} className="mb-5" />
                )}
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 justify-content-center gy-4">
              <Portfolio things={portfolio.things} />
            </div>
          </div>
        </section>

        <section
          className="brand-screen p-3 p-md-5 d-flex flex-column justify-content-center align-items-start brand-background-black"
          style={{
            backgroundColor: `${contact.background.color}`,
          }}
          id="contact"
        >
          <div class="container">
            <div className="row text-light fs-3 justify-content-center">
              <div className="col-12 col-md-6 text-center">
                {contact.title && (
                  <h1 className="brand-font display-1">{contact.title}</h1>
                )}
                {contact.body && (
                  <SmartText content={contact.body} className="mb-5" />
                )}
              </div>
            </div>
          </div>
        </section>
      </article>

      {modalStatus && (
        <>
          <Helmet>
            <body className="brand-body-menu-open" />
          </Helmet>
          <div
            className="brand-modal d-flex justify-content-center align-items-center brand-background-black"
            onClick={() => setModalStatus(false)}
          >
            <span
              className="brand-modal-close brand-text-white text-center p-3 fs-2"
              onClick={() => setModalStatus(false)}
              role="button"
            >
              <FontAwesomeIcon icon="times" />
            </span>
            <div className="brand-modal-content brand-background-black mb-5">
              <div className="ratio ratio-16x9">
                <iframe
                  id={settings.showreel}
                  class="brand-video-embed"
                  src={`https://player.vimeo.com/video/${settings.showreel}/`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen="false"
                  data-ready="true"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;

// ====

export const QUERY = graphql`
  query HomeDefaultQuery {
    index: storyblokEntry(slug: { eq: "home" }) {
      content
    }
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
    portfolio: storyblokEntry(slug: { eq: "portfolio" }) {
      content
    }
    contact: storyblokEntry(slug: { eq: "contact" }) {
      content
    }
  }
`;

Index.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Index.defaultProps = {
  data: null,
};

/*

        <nav className="brand-navigation-bar d-flex justify-content-between">
          <div className="brand-background-white brand-logo flex-fill">
            <span className="p-4">Ash Pears</span>
          </div>
          <div className="brand-background-white p-4">CX</div>
        </nav>

*/
