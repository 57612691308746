import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "../Global/Link";
import SmartText from "../../utils/TextHandler";
import ImageHandler from "../../utils/ImageHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

// ====

const PortfolioItem = (props) => {
  const { node } = props;

  const [modalStatus, setModalStatus] = useState(false);

  // ====

  return (
    <>
      <div className="col">
        <div className="card border-0 h-100 brand-card-animated ">
          <img
            src={ImageHandler(node.image.filename, "910x540")}
            className="card-img-top"
            alt={node.title}
          />
          <div className="card-img-overlay brand-animated brand-background-opaque">
            <div className="border border-3 border-light w-100 h-100 text-light p-3">
              {node.title && (
                <h5 class="card-title brand-font">{node.title}</h5>
              )}
              {node.description && <SmartText>{node.description}</SmartText>}

              <Link
                onClick={() => setModalStatus(true)}
                icon="play"
                button
                invert
              >
                Watch
              </Link>
            </div>
          </div>
        </div>
      </div>
      {modalStatus && (
        <>
          <Helmet>
            <body className="brand-body-menu-open" />
          </Helmet>
          <div
            className="brand-modal d-flex justify-content-center align-items-center brand-background-black"
            onClick={() => setModalStatus(false)}
          >
            <span
              className="brand-modal-close brand-text-white text-center p-3 fs-2"
              onClick={() => setModalStatus(false)}
              role="button"
            >
              <FontAwesomeIcon icon="times" />
            </span>
            <div className="brand-modal-content brand-background-black mb-5">
              <div className="ratio ratio-16x9">
                <iframe
                  id={node._uid}
                  class="brand-video-embed"
                  src={`https://player.vimeo.com/video/${node.video}/`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen="false"
                  data-ready="true"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PortfolioItem;

// ====

PortfolioItem.propTypes = {
  things: PropTypes.arrayOf,
};

PortfolioItem.defaultProps = {
  things: null,
};
